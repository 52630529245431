<template>
	<div class="container immerse-wrap">
		<div class="immerse-content">
			<div :class="isFinishedOrderModal === true ? 'addFullscreen' : ''"></div>
			<StepBar :step="1" />
			<div class="center-page-minus">
				<div class="order-counts">
					<p>訂單總計 <span></span> ${{ $stepPrice(cartTotal) }}元</p>
					<div class="order-expend" @click="handleExpend">
						<img src="../../assets/back.svg" :class="hideStatus" />
					</div>
				</div>
				<BySlider v-show="isShow">
					<div class="order-grid">
						<div class="order-items" v-for="(item, index) in cart" :key="index">
							<div class="order-name">{{ item.itemName }}</div>
							<div class="order-amount">x{{ item.count }}</div>
							<div class="order-price">
								${{ $stepPrice($countProductPrice(item)) }}
							</div>
							<OrderList :data="item" style="margin-top: 10px" />
						</div>
					</div>
				</BySlider>
			</div>
			<hr />
			<div class="center-page-minus remark" v-if="!isKiosk">
				<div class="order-inputs">
					<p>訂單備註</p>
				</div>
				<div style="width: 100%">
					<ByInput
						type="textarea"
						variant="textareaBasic"
						:value="remark"
						@input="setRemark"
					/>
				</div>
			</div>
			<div class="center-page-minus">
				<div class="get-time-title title">取餐方式</div>

				<ByRadio
					class="get-time-now"
					style="flex-wrap: wrap"
					name="DINE_IN"
					cantToggle
					v-model="pickUpMethod"
					title="內用"
					v-if="mobilePickupMethod.dineIn.methodSwitch"
				>
					<div
						class="get-time-time-content"
						v-if="
							pickUpMethod === 'DINE_IN' &&
							(mobilePickupMethod.dineIn.tableNumberSwitch ||
								mobilePickupMethod.dineIn.nameSwitch ||
								mobilePickupMethod.dineIn.phoneSwitch)
						"
					>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.dineIn.tableNumberSwitch"
							ref="dineInTableNumberRef"
						>
							<div
								v-if="pickUpMethod === 'DINE_IN' && errorValidate.tableNumber"
								class="error-validate"
							>
								- 請輸入桌號
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="tableNumber"
								:max="20"
								title="桌號"
								:class="{ 'error-input': errorValidate.tableNumber }"
							/>
						</div>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.dineIn.nameSwitch"
							ref="dineInUserNameRef"
						>
							<div
								v-if="pickUpMethod === 'DINE_IN' && errorValidate.userName"
								class="error-validate"
							>
								- 請輸入姓名
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userName"
								:max="20"
								title="姓名"
								:class="{ 'error-input': errorValidate.userName }"
							/>
						</div>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.dineIn.phoneSwitch"
							ref="dineInUserPhoneRef"
						>
							<div
								v-if="pickUpMethod === 'DINE_IN' && errorValidate.userPhone"
								class="error-validate"
							>
								- 請輸入手機號碼
							</div>
							<div
								v-if="
									pickUpMethod === 'DINE_IN' && errorValidate.userPhoneFormat
								"
								class="error-validate"
							>
								- 手機號碼格式不符
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userPhone"
								:max="10"
								title="手機"
								inputMode="numeric"
								:class="{
									'error-input':
										errorValidate.userPhone || errorValidate.userPhoneFormat,
								}"
							/>
						</div>
					</div>
				</ByRadio>

				<ByRadio
					class="get-time-now"
					style="flex-wrap: wrap"
					name="SELF"
					cantToggle
					v-model="pickUpMethod"
					title="自取"
					v-if="mobilePickupMethod.self.methodSwitch"
				>
					<div
						class="get-time-time-content"
						v-if="
							pickUpMethod === 'SELF' &&
							(mobilePickupMethod.self.nameSwitch ||
								mobilePickupMethod.self.phoneSwitch)
						"
					>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.self.nameSwitch"
							ref="selfUserNameRef"
						>
							<div
								v-if="pickUpMethod === 'SELF' && errorValidate.userName"
								class="error-validate"
							>
								- 請輸入姓名
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userName"
								:max="20"
								title="姓名"
								:class="{ 'error-input': errorValidate.userName }"
							/>
						</div>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.self.phoneSwitch"
							ref="selfUserPhoneRef"
						>
							<div
								v-if="pickUpMethod === 'SELF' && errorValidate.userPhone"
								class="error-validate"
							>
								- 請輸入手機號碼
							</div>
							<div
								v-if="pickUpMethod === 'SELF' && errorValidate.userPhoneFormat"
								class="error-validate"
							>
								- 手機號碼格式不符
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userPhone"
								:max="10"
								title="手機"
								inputMode="numeric"
								:class="{
									'error-input':
										errorValidate.userPhone || errorValidate.userPhoneFormat,
								}"
							/>
						</div>
					</div>
				</ByRadio>

				<ByRadio
					class="get-time-now"
					style="flex-wrap: wrap"
					name="DELIVERY"
					cantToggle
					v-model="pickUpMethod"
					title="外送"
					v-if="isBooking && mobilePickupMethod.delivery.methodSwitch"
					@input="selectDeliveryPickUpMethod"
				>
					<div
						class="get-time-time-content"
						v-if="
							pickUpMethod === 'DELIVERY' &&
							(mobilePickupMethod.delivery.addressSwitch ||
								mobilePickupMethod.delivery.nameSwitch ||
								mobilePickupMethod.delivery.phoneSwitch)
						"
					>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.delivery.addressSwitch"
							ref="deliveryAddressRef"
						>
							<div
								v-if="pickUpMethod === 'DELIVERY' && errorValidate.userAddress"
								class="error-validate"
							>
								- 請輸入地址
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userAddress"
								:max="20"
								title="地址"
								:class="{ 'error-input': errorValidate.userAddress }"
							/>
						</div>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.delivery.nameSwitch"
							ref="deliveryUserNameRef"
						>
							<div
								v-if="pickUpMethod === 'DELIVERY' && errorValidate.userName"
								class="error-validate"
							>
								- 請輸入姓名
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userName"
								:max="20"
								title="姓名"
								:class="{ 'error-input': errorValidate.userName }"
							/>
						</div>
						<div
							class="order-inputs-box"
							v-if="mobilePickupMethod.delivery.phoneSwitch"
							ref="deliveryUserPhoneRef"
						>
							<div
								v-if="pickUpMethod === 'DELIVERY' && errorValidate.userPhone"
								class="error-validate"
							>
								- 請輸入手機號碼
							</div>
							<div
								v-if="
									pickUpMethod === 'DELIVERY' && errorValidate.userPhoneFormat
								"
								class="error-validate"
							>
								- 手機號碼格式不符
							</div>
							<by-input
								@focus="scrollToInput"
								v-model="userPhone"
								:max="10"
								title="手機"
								inputMode="numeric"
								:class="{
									'error-input':
										errorValidate.userPhone || errorValidate.userPhoneFormat,
								}"
							/>
						</div>
					</div>
				</ByRadio>

				<div v-if="invoiceSwitch">
					<ByCheckbox
						v-model="isUseUniformNumber"
						title="開立有統一編號發票"
						@change="handleChangeUseUniformNumber"
						style="margin-bottom: 10px"
					/>
					<div
						v-if="isUseUniformNumber"
						class="order-inputs-box"
						ref="uniformNumberRef"
					>
						<div v-if="errorValidate.uniformNumber" class="error-validate">
							- 請輸入統編
						</div>
						<div
							v-if="errorValidate.uniformNumberFormat"
							class="error-validate"
						>
							- 統編格式不符
						</div>
						<by-input
							@focus="scrollToInput(e)"
							v-model="userUniformNumber"
							:max="8"
							title="統編"
							inputMode="numeric"
							:class="{
								'error-input':
									errorValidate.uniformNumber ||
									errorValidate.uniformNumberFormat,
							}"
						/>
					</div>
				</div>
			</div>

			<div class="center-page-minus kiosk-order-remark" v-if="isKiosk">
				<div class="kiosk-order-remark_title">訂單備註</div>
				<div class="kiosk-order-remark_content">
					{{ $store.state.kioskOrderRemark }}
				</div>
			</div>

			<template v-if="!isKiosk">
				<div class="center-page-minus">
					<div class="get-time-title title">取餐時間</div>
					<ByRadio
						class="get-time-now"
						name="takeTimeNow"
						cantToggle
						v-model="takeTimeType"
						title="馬上取餐"
						v-if="isOpen && pickUpMethod !== 'DELIVERY'"
					></ByRadio>
					<ByRadio
						class="get-time-time"
						name="takeTimeTime"
						cantToggle
						v-model="takeTimeType"
						title="指定時間取餐"
						v-if="isBooking"
					>
						<div
							class="get-time-time-content"
							v-if="takeTimeType === 'takeTimeTime'"
							ref="takeTimeTimeRef"
						>
							<div v-if="errorValidate.takeTimeTime" class="error-validate">
								- 請選擇取餐時間
							</div>
							<ByVuePicker
								class="get-time-time-content-date-select"
								:data="takeDateColumns"
								v-model="takeDate"
								placeholder="請選擇日期"
								:class="{ 'error-select': errorValidate.takeTimeTime }"
							/>
							<ByVuePicker
								ref="takeTimePkr"
								class="get-time-time-content-date-select"
								:data="getTimeList"
								v-model="takeTime"
								placeholder="請選擇時間"
								:class="{ 'error-select': errorValidate.takeTimeTime }"
							/>
						</div>
					</ByRadio>
				</div>
				<div style="height: 12px" />
				<div
					class="center-page-minus"
					v-if="isCashPaySwitch || isLinePaySwitch"
				>
					<div class="get-time-title title">付款方式</div>

					<ByRadio
						class="get-time-now"
						name="CASH"
						cantToggle
						v-model="payMethod"
						v-if="isCashPaySwitch"
					>
						<img
							src="../../assets/PayMethod-Cash.png"
							alt="CashPay"
							class="pay-method"
						/>
						<div style="width: 100%"></div>
					</ByRadio>

					<ByRadio
						class="get-time-now"
						name="LINE_PAY_ONLINE"
						cantToggle
						v-model="payMethod"
						v-if="isLinePaySwitch"
					>
						<img
							src="../../assets/PayMethod-LinePay.png"
							alt="LinePay"
							class="pay-method"
						/>
						<div style="width: 100%"></div>
					</ByRadio>
				</div>
				<div style="height: 12px" />
				<hr />
			</template>
			<div class="center-page-minus">
				<div style="height: 96px" />
				<ByOrderControl
					:names="['返回', '送出訂單']"
					:events="[goBack, goMeal]"
					:loadings="[false, submitLoading]"
				></ByOrderControl>
			</div>
		</div>
		<ByDialog
			v-model="isFinishedOrderModal"
			:title="'完成訂購'"
			:closable="false"
		>
			<template v-if="!isKiosk">
				<p>您可於【我的訂單】頁面開啟取餐畫面, 並出示畫面取餐。</p>
				<p>請準備足夠的現金, 於取餐時支付。</p>
			</template>
			<p v-else>請領取您的取餐單據，並準備足夠的現金，於取餐時支付。</p>
			<template slot="dialog-footer">
				<div class="dialog-footer">
					<ByButton stl="main1" @click="goTakeMeal">確認</ByButton>
				</div>
			</template>
		</ByDialog>
		<ByCompareCartConfirm ref="compareCartConfirmRef" />
	</div>
</template>

<script>
import { getOrderDetailList } from '@/utils/order'
import StepBar from '../layout/StepBar'
import OrderList from '@/views/buy/Components/OrderList'
import ByInput from '@/components/Input.vue'
import ByCheckbox from '@/components/Checkbox.vue'

export default {
	components: {
		ByInput,
		OrderList,
		StepBar,
		ByCheckbox,
	},
	data() {
		return {
			isMobile: false,
			isIOS: false,
			isAndroid: false,
			isShow: true,
			isFinishedOrderModal: false,
			submitLoading: false,
			isTableware: false,
			kioskFinishedOrderToStoreTimeout: null,
			takeTimeType: 'takeTimeNow',
			payMethod: 'CASH',
			takeDate: null,
			takeTime: null,
			takeTimeColumns: [],
			currentHour: null,
			userName: '',
			userPhone: '',
			userAddress: '',
			isUseUniformNumber: false,
			userUniformNumber: '',
			getTimeList: [[]],
			remark: '',
			tableNumber: null,
			pickUpMethod: 'DINE_IN',
			errorValidate: {
				userName: false,
				userPhone: false,
				userPhoneFormat: false,
				userAddress: false,
				takeTimeTime: false,
				tableNumber: false,
				uniformNumber: false,
				uniformNumberFormat: false,
			},
		}
	},
	computed: {
		invoiceSwitch() {
			const invoiceSwitch = this.$store.state.store.openInfo.invoiceSwitch
			return invoiceSwitch.admin && invoiceSwitch.store
		},
		cart() {
			return this.$store.getters['user/currCart']
		},
		cartTotal() {
			return this.$store.getters['user/cartTotal']
		},
		hideStatus() {
			return this.isShow ? 'order-hide' : 'order-show'
		},
		isGuest() {
			return this.$store.getters.isGuest
		},
		isKiosk() {
			return this.$store.getters.isKiosk
		},
		isOpen() {
			return this.$store.getters.isOpen
		},
		isBooking() {
			return this.$store.getters.isBooking
		},
		openInfo() {
			return this.$store.state.store.openInfo
		},
		isCashPaySwitch() {
			return this.$store.state.store.openInfo.payMethod.cashPaySwitch
		},
		isLinePaySwitch() {
			return this.$store.state.store.openInfo.payMethod.linePaySwitch
		},
		mobilePickupMethod() {
			return this.$store.state.store.openInfo.mobilePickupMethod
		},
		takeDateColumns() {
			const { openInfo } = this

			let list = []
			openInfo.bookingTimeList.forEach(e => {
				const todayDate = new Date()
				const today = this.$vtime.format(todayDate, 'yyyy-MM-dd')
				const startToday = new Date(today)

				const start = new Date(e.date)
				const rtext = this.transformDayRangeText(start, startToday)
				const mdstr = e.date.replace(/^\d{4}-/, '').replace('-', '/')
				const zhDay = ['日', '一', '二', '三', '四', '五', '六'][start.getDay()]
				list.push({
					value: {
						...e,
						timeList: [
							e.timeList.map(c => {
								return {
									value: c,
									label: c,
								}
							}),
						],
					},
					label: `${rtext} ${mdstr}(${zhDay})`,
				})
			})
			return [list]
		},
	},
	watch: {
		userName(newValue, oldValue) {
			if (newValue) {
				this.errorValidate.userName = false
			}
		},
		userPhone(newValue, oldValue) {
			const regex = /^[0-9\s]*$/
			if (!regex.test(newValue)) {
				this.$nextTick(() => {
					this.userPhone = ''
				})
			}

			if (newValue) {
				this.errorValidate.userPhone = false
				this.errorValidate.userPhoneFormat = false
			}
		},
		tableNumber(newValue, oldValue) {
			if (newValue) {
				this.errorValidate.tableNumber = false
			}
		},
		userAddress(newValue, oldValue) {
			if (newValue) {
				this.errorValidate.userAddress = false
			}
		},
		userUniformNumber(newValue, oldValue) {
			if (newValue) {
				this.errorValidate.uniformNumber = false
				this.errorValidate.uniformNumberFormat = false
			}
		},
		takeDate(value) {
			if (value) {
				const timeList = this.dateSelect()
				this.getTimeList = timeList
				this.takeTime = timeList[0][0].value

				this.errorValidate.takeTimeTime = false
				const takeDateElements = document.querySelectorAll('.by-picker')
				takeDateElements.forEach(element => {
					element.style.border = '1px solid #000' // 恢復黑框
				})
			}
		},
	},

	created() {},
	mounted() {
		this.initUserInfo()
		this.initPickUpMethod()
		this.initTakeTimeType()
		this.initPayMethod()
		this.isMobile =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent,
			)
		this.isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
		this.isAndroid = /Android/i.test(navigator.userAgent)
	},
	methods: {
		setRemark(value) {
			this.remark = value
		},
		dateSelect() {
			const timeList = []
			let aTime = Date.now()

			this.takeDate.timeList[0].forEach(time => {
				const select = new Date(
					`${this.takeDate.date.replace(new RegExp(/-/gm), '/')} ${time.value}`,
				)
				if (
					select.getTime() >
					aTime + (this.openInfo?.minimumBookInterval * 60 * 1000 || 0)
				) {
					timeList.push(time)
				}
			})
			return [timeList]
		},
		handleChangeUseUniformNumber() {},
		scrollToRequiredInput(refName) {
			const element = this.$refs[refName]
			if (element) {
				document.documentElement.scrollTo({
					top: element.offsetTop,
					behavior: 'smooth',
				})
			}
		},
		scrollToInput(e) {},
		// getOpenInfo() {
		//   if (this.$store.state.user.storeId == null) return
		//   this.$api.getOpenInfo(this.$store.state.user.storeId).then(res => {
		//     this.$store.commit('store/SET_TAKE_OPEN_INFO',res.data)
		//     this.takeDate = this.takeDateColumns[0][0]?.value
		//     this.takeTimeType = this.isOpen ? 'takeTimeNow' : 'takeTimeTime'
		//   })
		// },
		transformDayRangeText(date1, date2) {
			const r = date1 - date2
			if (r >= 0 && r < 86400000) return '今天'
			else if (r >= 86400000 && r < 172800000) return '明天'
			else if (r >= 172800000 && r < 259200000) return '後天'
			return ''
		},
		handleExpend() {
			this.isShow = !this.isShow
		},
		goTakeMeal() {
			if (this.isKiosk) {
				clearTimeout(this.kioskFinishedOrderToStoreTimeout)
				return this.$router.replace('/Store')
			}

			this.$api.order.getOrderInfo({}).then(res => {
				const {
					data: { hashId },
				} = res
				this.$router.push('TakeMeal/' + hashId)
			})
		},
		checkRouter() {
			let vm = this
			if (vm.$store.state.user.router === '/Cart') {
				vm.$router.push('/Cart')
			} else {
				vm.$router.go(-1)
			}
		},
		goBack() {
			this.checkRouter()
		},
		validName(pickUp) {
			const { nameSwitch } = pickUp
			if (nameSwitch) {
				if (!this.userName || !this.userName?.length) {
					return true
				}
			}
			return false
		},
		validPhone(pickUp) {
			const { phoneSwitch } = pickUp
			if (phoneSwitch) {
				if (!this.userPhone || !this.userPhone?.length) {
					this.errorValidate.userPhone = true
					return '請輸入手機號碼'
				}
				if (!/^[0-9]{10}$/g.test(this.userPhone)) {
					this.errorValidate.userPhoneFormat = true
					return '手機號碼格式不符'
				}
			}
			return null
		},
		async goMeal() {
			const { self, dineIn, delivery } = this.mobilePickupMethod
			if (this.pickUpMethod === 'SELF') {
				if (this.validName(self)) {
					this.errorValidate.userName = true
					this.scrollToRequiredInput('selfUserNameRef')
					return this.$alert('請輸入姓名', 'danger')
				}

				const message = this.validPhone(self)
				if (message) {
					this.scrollToRequiredInput('selfUserPhoneRef')
					return this.$alert(message, 'danger')
				}
			} else if (this.pickUpMethod === 'DINE_IN') {
				const { tableNumberSwitch } = dineIn
				if (tableNumberSwitch) {
					if (!this.tableNumber || !this.tableNumber?.length) {
						this.errorValidate.tableNumber = true
						this.scrollToRequiredInput('dineInTableNumberRef')
						return this.$alert('請輸入桌號', 'danger')
					}
				}

				if (this.validName(dineIn)) {
					this.errorValidate.userName = true
					this.scrollToRequiredInput('dineInUserNameRef')
					return this.$alert('請輸入姓名', 'danger')
				}

				const message = this.validPhone(dineIn)
				if (message) {
					this.scrollToRequiredInput('dineInUserPhoneRef')
					return this.$alert(message, 'danger')
				}

				if (!this.tableNumber || !this.tableNumber?.length) {
					this.tableNumber = ''
				}
			} else if (this.pickUpMethod === 'DELIVERY') {
				const { addressSwitch } = delivery
				if (addressSwitch) {
					if (!this.userAddress || !this.userAddress?.length) {
						this.errorValidate.userAddress = true
						this.scrollToRequiredInput('deliveryUserAddressRef')
						return this.$alert('請輸入地址', 'danger')
					}
				}

				if (this.validName(delivery)) {
					this.errorValidate.userName = true
					this.scrollToRequiredInput('deliveryUserNameRef')
					return this.$alert('請輸入姓名', 'danger')
				}

				const message = this.validPhone(delivery)
				if (message) {
					this.scrollToRequiredInput('deliveryUserPhoneRef')
					return this.$alert(message, 'danger')
				}
			}

			if (this.isUseUniformNumber) {
				if (!this.userUniformNumber) {
					this.errorValidate.uniformNumber = true
					this.scrollToRequiredInput('uniformNumberRef')
					return this.$alert('請輸入統編', 'danger')
				} else if (!this.checkUniformNumber(this.userUniformNumber)) {
					this.errorValidate.uniformNumberFormat = true
					this.scrollToRequiredInput('uniformNumberRef')
					return this.$alert('統編格式不符', 'danger')
				}
			}

			if (this.takeTimeType === 'takeTimeTime' && this.takeDate === null) {
				this.errorValidate.takeTimeTime = true

				const takeDateElements = document.querySelectorAll('.by-picker')
				takeDateElements.forEach(element => {
					element.style.border = 'none' // 隱藏黑框
				})

				this.scrollToRequiredInput('takeTimeTimeRef')
				return this.$alert('請選擇取餐時間', 'danger')
			}

			const { isTableware, cart } = this
			const { storeId } = this.$store.state.user
			this.submitLoading = true
			const cartConfirmAwait = await this.$refs.compareCartConfirmRef.confirm()

			if (!cartConfirmAwait) {
				return
			}

			try {
				if (!this.$store.state.socket.stompClient?.connected) {
					throw '連線失敗，請稍等片刻後再嘗試'
				}

				const orderDetailList = getOrderDetailList(cart)

				await this.$store
					.dispatch('socket/addOrder', {
						storeId,
						tableware: isTableware,
						orderDetailList: orderDetailList,
						machineType: this.isKiosk ? 'kiosk' : 'POS',
						remark: this.isKiosk
							? this.$store.state.kioskOrderRemark
							: this.remark,
						takeTime:
							this.takeTimeType === 'takeTimeTime'
								? `${this.takeDate.date} ${this.takeTime}`
								: null,
						payMethod: this.payMethod,
						name: this.userName,
						phone: this.userPhone,
						address: this.userAddress,
						uniformNumber: this.isUseUniformNumber
							? this.userUniformNumber
							: '',
						discountPrice: 0,
						entertain: false,
						paid: false,
						tableNumber: this.tableNumber,
						pickUpMethod: this.pickUpMethod,
					})
					.then(res => {
						this.$store.state.kioskOrderRemark = ''
						this.remark = ''
						if (this.isKiosk) {
							this.$store.commit('user/SET_CART_ITEM', [])
							this.submitLoading = false

							clearTimeout(this.kioskFinishedOrderToStoreTimeout)
							this.kioskFinishedOrderToStoreTimeout = setTimeout(() => {
								this.$router.replace('/Store')
							}, 5000)
							return
						}

						this.$store.commit('user/SET_USER_NAME', this.userName)
						this.$store.commit('user/SET_USER_PHONE', this.userPhone)
						this.$store.commit('user/SET_USER_ADDRESS', this.userAddress)
						this.$store.commit(
							'user/SET_USER_UNIFORM_NUMBER',
							this.userUniformNumber,
						)

						sessionStorage.setItem('yPosition', '0')

						const {
							data: {
								id,
								hashId,
								transactionStatus,
								paymentStatus,
								message,
								webPaymentUrl,
								appPaymentUrl,
							},
						} = res

						if (paymentStatus === null) {
							this.$store.commit('user/SET_CART_ITEM', [])
							this.$store.commit('myOrder/SET_FINISH_ORDER', true)
							if (hashId) {
								this.$router.push('TakeMeal/' + hashId)
							} else {
								this.$router.push('TakeMeal/' + id)
							}
						} else if (paymentStatus === 'TO_BE_CONFIRMED') {
							window.location.href = webPaymentUrl
						} else {
							this.$alert(message, 'danger')
							this.$store.commit('user/SET_CART_ITEM', [])
							this.$router.replace('/Store')
						}
					})
					.catch(err => {
						// 若餐點可能有更動，則返回錯誤
						if (
							err.errorCode &&
							(err.errorCode.startsWith('ORDER_') ||
								err.errorCode.startsWith('OPTION_'))
						) {
							if (err.errorCode.startsWith('ORDER_')) {
								this.$alert(err.message, 'danger')
							} else {
								const customErrMessage = err.message + ' ， 請修改餐點!'

								this.$alert(customErrMessage, 'danger')
							}
						} else {
							this.$alert('新增訂單異常!請刷新再試', 'danger')
						}
						this.submitLoading = false
					})
			} catch (err) {
				this.$alert('新增訂單異常!請刷新再試', 'danger')
				this.submitLoading = false
			}
		},
		/**
		 * 檢查營業人統一編號：
		 * 1. 統一編號預估空號將於 2024 年用罄，故擴增統一編號。
		 * 2. 為相容新舊統一編號，檢查邏輯由可被『10』整除改為可被『5』整除。
		 *
		 * @param uniformNo		統一編號，型別需為String
		 * @returns {boolean}	檢查結果
		 */
		checkUniformNumber(uniformNo) {
			uniformNo = uniformNo.trim()
			if (uniformNo.length === 0) {
			}

			const pattern = /^\d{8}$/
			if (!pattern.test(uniformNo)) {
				return false
			}

			let isLegal = false
			const weight = '12121241'
			let the7thNumberIs7 = false
			let sum = 0
			for (let i = 0; i < 8; i++) {
				const tmp = parseInt(uniformNo.charAt(i)) * parseInt(weight.charAt(i))
				sum += Math.floor(tmp / 10) + (tmp % 10) // 取出十位數和個位數相加
				if (i === 6 && uniformNo.charAt(i) === '7') {
					the7thNumberIs7 = true
				}
			}

			if (the7thNumberIs7) {
				if (sum % 5 === 0 || (sum + 1) % 5 === 0) {
					isLegal = true
				}
			} else {
				if (sum % 5 === 0) {
					isLegal = true
				}
			}

			return isLegal
		},
		initUserInfo() {
			this.userName = this.$store.state.user.userName
			this.userPhone = this.$store.state.user.userPhone
			this.userAddress = this.$store.state.user.userAddress
			this.userUniformNumber = this.$store.state.user.userUniformNumber
		},
		initPickUpMethod() {
			const { self, dineIn, delivery } = this.mobilePickupMethod
			if (dineIn.methodSwitch) {
				this.pickUpMethod = 'DINE_IN'
			} else if (self.methodSwitch) {
				this.pickUpMethod = 'SELF'
			} else if (delivery.methodSwitch) {
				this.pickUpMethod = 'DELIVERY'
			}
		},
		initTakeTimeType() {
			if (this.isOpen) {
				this.takeTimeType = 'takeTimeNow'
			} else if (this.isBooking) {
				this.takeTimeType = 'takeTimeTime'
			}
		},
		initPayMethod() {
			if (this.isCashPaySwitch) {
				this.payMethod = 'CASH'
			} else if (this.isLinePaySwitch) {
				this.payMethod = 'LINE_PAY_ONLINE'
			}
		},
		selectDeliveryPickUpMethod() {
			if (this.isBooking) {
				this.takeTimeType = 'takeTimeTime'
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			const { cart } = vm
			if (cart.length === 0) {
				next(`/${to.params.random}/Store`)
			}
		})
	},
}
</script>

<style lang="sass" scoped>
.remark
	border-bottom: 1px solid #D8D8D899
	padding-bottom: 10px !important
	margin-bottom: 10px !important

.addFullscreen
	position: fixed
	width: 100%
	height: 100%
	left: 0
	top: 0
	background: rgba(#000, 0.5)
	z-index: 3

.container
	margin-bottom: 130px

.get-time-title
	font-size: 18px
	font-weight: bold
	padding: 10px 0 15px

.get-time-now
	margin-bottom: 15px

.pay-method
	max-width: 6rem
	height: auto

.get-time-time
	flex-wrap: wrap

.get-time-time-content
	width: 100%
	padding-top: 10px

//.get-time-time-range
//  display: flex
//  align-items: center
//  width: 100%
//  > *
//    width: 50%
//    &:first-child
//      margin-right: 8px

.get-time-time-content-date-select
	margin-bottom: 10px

.order
	&-inputs
		height: 20px
		font-size: 18px
		font-weight: bold
		margin: 12px 0

		&-box
			margin-bottom: 10px

			&::v-deep
				input
					padding-left: 95px

	&-counts
		display: flex
		align-items: center
		justify-content: space-between
		height: 20px
		font-size: 18px
		font-weight: bold
		margin: 10px 0 20px 0

		p
			span
				display: inline-block
				background-color: #e2e5ec
				position: relative
				top: 3px
				width: 1px
				height: 18px
				margin: 0 10px

	&-expend
		height: 18px
		width: 18px
		margin-left: 50px

		img
			transition: all 0.3s
			transform: rotate(-90deg)
			width: 100%
			height: 100%

			&.order-show
				transform: rotate(90deg)

	&-grid
		transition: all 0.5s
		font-size: 16px

	&-items
		display: flex
		flex-wrap: wrap
		padding: 20px 0
		border-top: 1px solid #D8D8D899

	&-name
		min-width: 58.5%
		padding-right: 10px

	&-price
		flex: 1
		text-align: right
		color: #432d2c

	&-amount
		min-width: 25%
		font-weight: 900
		font-size: 16px
		color: #432d2c

	&-information
		padding-top: 20px
		border-bottom: 1px solid #D8D8D899

		p
			font-size: 18px
			font-weight: bold
			margin-bottom: 20px

		.by-input
			margin-bottom: 20px
			display: block

	&-table
		padding-top: 20px

.here, .left
	margin-top: 20px

::v-deep .by-checkbox
	label
		width: 100% !important

hr
	width: 100%
	height: 1px
	border: 0
	background-color: #D8D8D899

.kiosk-order-remark
	padding-top: 16px

	&_title
		margin-bottom: 8px

	&_content
		line-height: 1.3
		font-weight: 400
		font-size: 14px

.error-validate
	font-size: 12.5px
	color: red

.error-input
	border: 2px solid red
	border-radius: 50px

.error-select
	border: 2px solid red
</style>
